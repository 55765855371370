import * as React from "react"

import { Link } from "gatsby"

import pacman from "../images/pacman.jpeg"
import memoryGame from "../images/ocean-memory-game.jpeg"

import "./index.styles.css"

const IndexPage = () => {
  const learnMoreRef = React.createRef()
  setTimeout(() => {
    learnMoreRef.current.classList.add("animate__bounce")
  }, 2000)

  return (
    <main>
      <title>Noodle Nectar</title>
      <div className="hero">
        <div className="copy">
          <h1>Noodle Nectar</h1>
          <h2>nutrient dense games for your brain</h2>
        </div>
        <span ref={learnMoreRef} className="learn-more animate__animated">
          scroll to learn more
        </span>
      </div>
      <div className="section">
        <div className="section-contents">
          <p>
            We are currently developing several familiar games that are <strong>fun</strong>,{" "}
            <strong>accessible</strong>, and also <strong>useful</strong>!
          </p>
        </div>

        <img src={pacman} role="presentation" alt="pac man" />
      </div>
      <div className="section flipped altcolor-b">
        <img src={memoryGame} role="presentation" alt="pac man" />
        <div className="section-contents">
          <p>
            We believe that the right kinds of games are a great tool for exercising your brain. We aren't scientists,
            so we can't make any official claims here... but we make games that we think are good for your brain, and
            your soul. Accessible right here from your computer, phone, or tablet.
          </p>
        </div>
      </div>
      <div className="section altcolor-c">
        <div className="section-contents">
          <p>
            We are hard at work, but if you would like to stay in touch then let us by sharing your email, or following
            us on twitter
            <form>
              <input type="text" name="email" placeholder="your@email.com" />
              <input type="submit" value="Keep me updated" />
              <span className="disclaimer">
                We promise to keep your email address private, and never share it with any 3rd party. You will receive
                at maximum <strong>one</strong> email per week from us containing status updates.
              </span>
            </form>
          </p>
        </div>
      </div>
      <div className="footer">
        Noodle Nectar 2021
        <span className="disclaimer">once again, we make no official scientific or medical claims. happy gaming!</span>
      </div>
    </main>
  )
}

export default IndexPage
